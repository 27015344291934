<div>
  <h2 mat-dialog-title>{{ title }}</h2>
  <input class="filterInput" matInput type="text" placeholder="Filtrar por..." [(ngModel)]="searchText" />
</div>
<mat-dialog-content>
  <span class="selected" *ngFor="let s of selected">
    <span class="name">{{ s.name }}</span>
    <span class="delete" (click)="removeCandidate(s)" size="small">
      <mat-icon>delete_outline</mat-icon>
    </span>
  </span>
  <mat-list>
    <mat-list-item *ngFor="let c of candidates">
      <mat-label>{{ c.name }}</mat-label>
      <button mat-mini-fab slot="end" (click)="addCandidate(c)">
        <mat-icon>add</mat-icon>
      </button>
    </mat-list-item>
  </mat-list>
</mat-dialog-content>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="selected" cdkFocusInitial>Aceptar</button>
  <button mat-button (click)="onNoClick()">Cancelar</button>
</div>