import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { pinkIcon } from 'app/shared/constants';
import * as Leaflet from 'leaflet';

@Component({
  selector: 'app-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss']
})
export class LocationSelectorComponent implements OnDestroy, OnInit {

  lat: number;
  lon: number;
  map?: Leaflet.Map;

  private markersGroup: Leaflet.LayerGroup = new Leaflet.LayerGroup();
  private markers: Leaflet.Marker[] = [];

  constructor(
    public dialogRef: MatDialogRef<LocationSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.lat = data.lat;
      this.lon = data.lon;
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.initMap();
  }

  initMap() {
    if (this.map) {
      this.map.remove();
    }
    this.map = Leaflet.map('mapLocationSelector').setView([this.lat, this.lon], 13);
    this.markersGroup.addTo(this.map);
    Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'trobatea.com',
    }).addTo(this.map);

    this.map.on('click', (data: any) => {
      this.setMarker(data.latlng.lat, data.latlng.lng);
    });

    if (this.lat && this.lon) {
      this.setMarker(this.lat, this.lon);
    }
  }

  ngOnDestroy() {
    if (this.map) {
      this.map.remove();
    }
  }

  setMarker(lat: number, lon: number) {
    if (this.map) {
      if (this.markers.length > 0) {
        this.markersGroup.removeLayer(this.markers[0]);
        this.markers.shift();
      }
      const marker = Leaflet.marker([lat, lon], { icon: Leaflet.icon(pinkIcon) });
      this.map.setView([lat, lon], this.map.getZoom());
      marker.addTo(this.markersGroup);
      this.markers.push(marker);
      marker.on('click', (event: any) => {
        try {
          this.dialogRef.close(event.latlng);
        } catch (e) { }
      });
    }
  }
}
