import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface ICandidate {
  id: number;
  name: string;
}

@Component({
  selector: 'app-autocomplete-picker-dialog',
  templateUrl: './autocomplete-picker-dialog.component.html',
  styleUrls: ['./autocomplete-picker-dialog.component.scss']
})
export class AutocompletePickerDialogComponent {
  searchText: string;
  selected: ICandidate[];
  private _candidates: ICandidate[] = [];
  title: string;

  constructor(
    public dialogRef: MatDialogRef<AutocompletePickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.searchText = '';
      this.selected = data.selected.map((i: ICandidate) => ({ id: i.id, name: i.name })).sort(this.sortByName);
      this.title = data.title;
      this._candidates = data.list.filter((c: ICandidate) => !this.selected.find(s => s.id === c.id)).sort(this.sortByName);
  }

  addCandidate(candidate: ICandidate) {
    const list = this.selected;
    list.push(candidate);
    this.selected = list.sort(this.sortByName);
    this._candidates = this._candidates.filter(i => i.id !== candidate.id);
  }

  get candidates() {
    const re = new RegExp(this.searchText.trim().toLowerCase(), 'i');
    return this._candidates.filter(c => c.name.match(re));
  }

  onNoClick(): void {
    this.dialogRef.close(); // { selected: this.selected });
  }

  removeCandidate(candidate: ICandidate) {
    this.selected = this.selected.filter(c => c.id !== candidate.id);
    this._candidates.push(candidate);
    this._candidates = this._candidates.sort(this.sortByName);
  }

  sortByName(a: ICandidate, b: ICandidate) {
    return (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
  }

}
