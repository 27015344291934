import { NgModule } from '@angular/core';
import { MenuItems } from './menu-items/menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { HtmlEditorComponent } from './html-editor/html-editor.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AutocompletePickerComponent } from './autocomplete-picker/autocomplete-picker.component';
import { AutocompletePickerDialogComponent } from './autocomplete-picker/autocomplete-picker-dialog/autocomplete-picker-dialog.component';
import { LocationSelectorComponent } from './location-selector/location-selector.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DemoMaterialModule } from 'app/demo-material-module';

@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    AutocompletePickerComponent,
    AutocompletePickerDialogComponent,
    ConfirmDialogComponent,
    HtmlEditorComponent,
    LocationSelectorComponent
  ],
  entryComponents: [ ConfirmDialogComponent ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    AutocompletePickerComponent,
    AutocompletePickerDialogComponent,
    ConfirmDialogComponent,
    HtmlEditorComponent,
    LocationSelectorComponent
  ],
  imports: [
    AngularEditorModule,
    CommonModule,
    DemoMaterialModule,
    FormsModule,
  ],
  providers: [ MenuItems ]
})
export class SharedModule { }
