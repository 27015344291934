import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalEditorGuard implements CanActivate {
  constructor(private auth: AuthService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isExternalEditor = this.auth.isUserRole('external-editor');
    return !isExternalEditor;
  }

}
