// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  domain: 'https://backpack-staging.trobatea.com',
  imageUriPrefix: 'https://campobase.trobatea.com/sites/default/files/styles/600x400/public/events/',
  production: false,
  sherpaUri: 'https://sherpa-staging.trobatea.com/',
  drawsUri: 'https://campobase.trobatea.com/api/mvp/views/draws?display_id=services_1',
  oneSignalAppId: 'e0d78782-3671-4512-96ae-6261238deb58',
  analyticsClientID: 'UA-52135659-2',
  oauthClientId: '501238103106-1p2jnjr132mtv8ctse1kqnogkicsqrko.apps.googleusercontent.com',
  facebookClientId: '1248108685391549',
  messagingSenderId: '501238103106',
  mapbox: {
    bbox: '-1.534266,37.841230,0.550502,40.795628',
    proximity: '-0.674680,39.472926',
    apiToken: 'pk.eyJ1IjoidHJydWl6IiwiYSI6ImNrNjljbTdnMzBkYjIzbHBrYmtvcWl6Nm8ifQ.npJfS2A29c8zNuKcJKMuuA'
  },
};
