import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { IUser } from 'models/user.model';
import { IActivity } from 'models/activity.model';
import { ILoginCredentials } from 'models/auth.model';
import { ISearch } from 'models/search.model';
import { IProvince } from 'models/geo.model';
import { ICategory } from 'models/category.model';
import { IDestination, IDestinationSearch } from 'models/destination.model';
import { IPost } from 'models/post.model';
import { IVillage } from 'models/village.model';
import { IConfigParam, IConfigParamSearch } from 'models/config-param.model';

@Injectable({
  providedIn: 'root'
})
export class SherpaService {

  // Http Headers
  private httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': environment.domain,
    })
  };
  private apiVersion = 'api/v2';

  private get baseUrl(): string {
    return environment.sherpaUri + this.apiVersion;
  }

  constructor(private http: HttpClient) { }

  async castPostToActivity(id: number) {
    return this.http.put(this.baseUrl + '/post/cast/' + id, {}, this.httpOptions).toPromise() as Promise<IActivity>;
  }

  async checkJWT() {
    return this.http.get(environment.sherpaUri + this.apiVersion + '/auth/check', this.httpOptions).toPromise() as Promise<string>;
  }

  async createUserProfile(user: IUser) {
    return this.http.post(this.baseUrl + '/user/', user, this.httpOptions);
  }

  public async deleteVillage(id: string): Promise<any> {
    const url = this.baseUrl + '/village/' + id;
    return this.http.delete(url, this.httpOptions).toPromise() as Promise<any>;
  }

  public async getActivity(idOrSlug: string): Promise<IActivity> {
    const url = this.baseUrl + '/activity/' + idOrSlug;
    return this.http.get(url, this.httpOptions).toPromise() as Promise<IActivity>;
  }

  public async getActivities(ids: number[]): Promise<IActivity[]> {
    const url = this.baseUrl + '/activity/';
    return this.http.post(url, { ids }, this.httpOptions).toPromise() as Promise<IActivity[]>;
  }

  public async getActivityListCount(searchModel: ISearch): Promise<number> {
    const url = this.baseUrl + '/activity/listCount';
    return this.http.post(url, searchModel, this.httpOptions).toPromise() as Promise<number>;
  }

  public async getConfigParam(id: string | number): Promise<IDestination> {
    const url = this.baseUrl + '/configParam/' + id;
    return this.http.get(url, this.httpOptions).toPromise() as Promise<IDestination>;
  }

  public async getConfigParamListCount(searchModel: IDestinationSearch): Promise<number> {
    const url = this.baseUrl + '/configParam/listCount';
    return this.http.post(url, searchModel, this.httpOptions).toPromise() as Promise<number>;
  }

  public async getDestination(id: string | number): Promise<IDestination> {
    const url = this.baseUrl + '/destination/' + id;
    return this.http.get(url, this.httpOptions).toPromise() as Promise<IDestination>;
  }

  public async getDestinationListCount(searchModel: IDestinationSearch): Promise<number> {
    const url = this.baseUrl + '/destination/listCount';
    return this.http.post(url, searchModel, this.httpOptions).toPromise() as Promise<number>;
  }

  public async getPost(id: string | number): Promise<IPost> {
    const url = this.baseUrl + '/post/' + id;
    return this.http.get(url, this.httpOptions).toPromise() as Promise<IPost>;
  }

  public async getPostListCount(searchModel: ISearch): Promise<number> {
    const url = this.baseUrl + '/post/listCount';
    return this.http.post(url, searchModel, this.httpOptions).toPromise() as Promise<number>;
  }

  public async getProvince(id: number): Promise<IProvince> {
    const url = this.baseUrl + '/geo/province/' + id;
    return this.http.get(url, this.httpOptions).toPromise() as Promise<IProvince>;
  }

  public async getVillage(id: string | number): Promise<IVillage> {
    const url = this.baseUrl + '/village/' + id;
    return this.http.get(url, this.httpOptions).toPromise() as Promise<IVillage>;
  }

  public async getVillageListCount(searchModel: ISearch): Promise<number> {
    const url = this.baseUrl + '/village/listCount';
    return this.http.post(url, searchModel, this.httpOptions).toPromise() as Promise<number>;
  }

  public async getUserListCount(searchModel: ISearch): Promise<number> {
    const url = this.baseUrl + '/user/listCount';
    return this.http.post(url, searchModel, this.httpOptions).toPromise() as Promise<number>;
  }

  public async getApiVersionInfo(): Promise<any> {
    const url = this.baseUrl;
    return this.http.get(url, this.httpOptions).toPromise();
  }

  private getHttpOptionsForText() {
    const options: any = Object.assign({}, this.httpOptions);
    options.responseType = 'text';
    return options;
  }

  public async getUserProfile(uuid: string) {
    const url = this.baseUrl + '/user/profile/' + uuid;
    return this.http.get(url, this.httpOptions).toPromise() as Promise<IUser>;
  }

  public async getUserToken(uuid: string) {
    const url = this.baseUrl + '/auth/login';
    return this.http.post(url, { uuid }, this.httpOptions).toPromise() as Promise<string>;
  }

  public async getUsersCsv() {
    const url = this.baseUrl + '/user/csv';
    const csv = await this.http.get(url, this.getHttpOptionsForText()).toPromise();
    const link = document.createElement('a');
    link.setAttribute('href', URL.createObjectURL(new Blob([csv])));
    link.setAttribute('download', 'usuarios' + new Date().toLocaleString() + '.csv');
    link.setAttribute('id', 'downloadLink');
    const node = document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(node);
  }

  public async listActivities(searchModel: ISearch): Promise<IActivity[]> {
    const url = this.baseUrl + '/activity/list';
    return this.http.post(url, searchModel, this.httpOptions).toPromise() as Promise<IActivity[]>;
  }

  public async listConfigParams(searchModel: IConfigParamSearch): Promise<IConfigParam[]> {
    const url = this.baseUrl + '/configParam/list';
    return this.http.post(url, searchModel, this.httpOptions).toPromise() as Promise<IConfigParam[]>;
  }

  public async listDestinations(searchModel: IDestinationSearch): Promise<IDestination[]> {
    const url = this.baseUrl + '/destination/list';
    return this.http.post(url, searchModel, this.httpOptions).toPromise() as Promise<IDestination[]>;
  }

  public async listPosts(searchModel: ISearch): Promise<IPost[]> {
    const url = this.baseUrl + '/post/list';
    return this.http.post(url, searchModel, this.httpOptions).toPromise() as Promise<IPost[]>;
  }

  public async listVillages(searchModel: ISearch): Promise<IVillage[]> {
    const url = this.baseUrl + '/village/list';
    return this.http.post(url, searchModel, this.httpOptions).toPromise() as Promise<IVillage[]>;
  }

  public async listUsers(searchModel: ISearch): Promise<IUser[]> {
    const url = this.baseUrl + '/user/list';
    return this.http.post(url, searchModel, this.httpOptions).toPromise() as Promise<IUser[]>;
  }

  public async login(creds: ILoginCredentials) {
    const url = this.baseUrl + '/auth/login';
    return this.http.post(url, creds, this.httpOptions).toPromise() as Promise<IUser>;
  }

  public async saveActivity(data: any) {
    let url = this.baseUrl + '/activity';
    if (data.id === 'add') {
      delete data.id;
    }
    const activityId = data.id;
    let result;
    if (activityId) {
      url += `/${activityId}`;
      result = this.http.put(url, data, this.httpOptions).toPromise() as Promise<IActivity>;
    } else {
      result = this.http.post(url, data, this.httpOptions).toPromise() as Promise<IActivity>;
    }
    return result;
  }

  public async saveConfigParam(id: number, value: any) {
    let url = this.baseUrl + `/configParam/${id}`;
    return this.http.patch(url, { value }, this.httpOptions).toPromise() as Promise<IConfigParam>;
  }

  public async saveDestination(data: any) {
    let url = this.baseUrl + '/destination';
    if (data.id === 'add') {
      delete data.id;
    }
    const destinationId = data.id;
    let result;
    if (destinationId) {
      url += `/${destinationId}`;
      result = this.http.put(url, data, this.httpOptions).toPromise() as Promise<IDestination>;
    } else {
      result = this.http.post(url, data, this.httpOptions).toPromise() as Promise<IDestination>;
    }
    return result;
  }

  public async saveVillage(data: any) {
    let url = this.baseUrl + '/village';
    if (data.id === 'add') {
      delete data.id;
    }
    const villageId = data.id;
    let result;
    if (villageId) {
      url += `/${villageId}`;
      result = this.http.put(url, data, this.httpOptions).toPromise() as Promise<IVillage>;
    } else {
      result = this.http.post(url, data, this.httpOptions).toPromise() as Promise<IVillage>;
    }
    return result;
  }

  public async sendPasswordRecoveryMail(email: string): Promise<boolean> {
    const url = this.baseUrl + '/auth/resetPassword?email=' + email;
    return this.http.get(url, this.httpOptions).toPromise() as Promise<boolean>;
  }

  async updatePost(data: any): Promise<any> {
    return this.http.put(this.baseUrl + '/post/', data, this.httpOptions).toPromise() as Promise<any>;
  }

  public async updateUserPassword(token: string, password: string): Promise<boolean> {
    const url = this.baseUrl + '/user/password/';
    return this.http.put(url, { password }, this.httpOptions).toPromise() as Promise<boolean>;
  }

  public async updateUserProfile(uuid: string, data: IUser) {
    const url = this.baseUrl + '/user/profile/' + uuid;
    return this.http.put(url, data, this.httpOptions).toPromise();
  }

  public async uploadPictures(entityName: string, entityId: number, data: any) {
    const url = `${this.baseUrl}/${entityName}/${entityId}/upload`;
    return this.http.put(url, data, this.httpOptions).toPromise() as Promise<any>;
  }
}
