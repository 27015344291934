import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'services/auth/auth.service';

@Pipe({
  name: 'filterMenuByRole'
})
export class FilterMenuByRolePipe implements PipeTransform {
  constructor(private auth: AuthService) { }

  transform(value: Array<any>, ...args: unknown[]): any {
    const role = this.auth.getRole();
    return value.filter(item => {
      if (role === 'external-editor') {
        return item.roleType === 'all-roles' ? item : null;
      } else {
        return item;
      }
    });
  }
}
