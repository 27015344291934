import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { ToasterService } from '../services/toaster/toaster.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authSrv: AuthService,
    private toaster: ToasterService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const modified = req.clone({
      setHeaders: { auth: this.authSrv.getAccessToken() }
    });
    return next.handle(modified).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.toaster.showWarningMessage('La sessión ha caducado. Inicie sesión e inténtelo de nuevo.');
          this.authSrv.logOut();
        }
        return throwError(error);
      })
    );
  }
}
