import { FocusMonitor } from '@angular/cdk/a11y';

import { Component, Input, ChangeDetectionStrategy, ElementRef, ViewChild, HostBinding, OnDestroy,
  Optional, Self, ChangeDetectorRef, OnChanges } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material';
import { Subject } from 'rxjs';

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: HtmlEditorComponent }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HtmlEditorComponent implements ControlValueAccessor, MatFormFieldControl<string>, OnChanges, OnDestroy {
  static nextId = 0;

  // tslint:disable-next-line: no-inferrable-types
  @Input() content!: string | null;

  @ViewChild('htmleditor', { static: true })
  htmleditor!: ElementRef<any>;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'no',
    enableToolbar: true,
    showToolbar: true,
    placeholder: '',
    defaultParagraphSeparator: 'p',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
    ],
    customClasses: [
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'fontName',
      ],
      [
        // 'fontSize',
        'textColor',
        'backgroundColor',
        'foreColor',
        'customClasses',
        // 'link',
        // 'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
      ]
    ]
  };

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef
  ) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
    fm.monitor(this.elRef.nativeElement, true).subscribe((origin: any) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  get value() {
    return this.content;
  }

  set value(val: string | null) {
    this.content = val;
    this.stateChanges.next();
  }

  @HostBinding() id = `html-editor-input-${HtmlEditorComponent.nextId++}`;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder!: string;

  get empty(): boolean {
    return !this.content || this.content.length === 0;
  }

  focused!: boolean;

  errorState = false;

  controlType = 'html-editor';

  autofilled?: boolean | undefined;

  stateChanges = new Subject<void>();

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required = false;

  setDescribedByIds(ids: string[]): void {
    // tslint:disable-next-line: no-non-null-assertion
    const controlElement = this.elRef.nativeElement
      .querySelector('.html-editor-container')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input')?.focus();
    }
  }

  private onChange = (_: string) => { };
  private onTouch = () => { };

  ngOnChanges(event: any) {
    if (typeof event === 'string') {
      this.onChange(event);
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  writeValue(val: string): void {
    this.content = val ? val.slice() : val;
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

}
