import { Routes } from '@angular/router';
import { AutenticatedGuard } from 'guards/autenticated.guard';
import { ExternalEditorGuard } from 'guards/external-editor.guard';
import { CleanComponent } from './layouts/clean/clean.component';
import { FullComponent } from './layouts/full/full.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      // {
      //   path: '',
      //   loadChildren:
      //     () => import('./material-component/material.module').then(m => m.MaterialComponentsModule)
      // },
      {
        path: 'activities',
        loadChildren: () => import('./activities/activities.module').then(m => m.ActivitiesModule),
        canActivate: [AutenticatedGuard]
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AutenticatedGuard]
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
        canActivate: [AutenticatedGuard, ExternalEditorGuard]
      },
      {
        path: 'destinations',
        loadChildren: () => import('./destinations/destinations.module').then(m => m.DestinationsModule),
        canActivate: [AutenticatedGuard, ExternalEditorGuard]
      },
      {
        path: 'posts',
        loadChildren: () => import('./posts/posts.module').then(m => m.PostsModule),
        canActivate: [AutenticatedGuard, ExternalEditorGuard]
      },
      {
        path: 'villages',
        loadChildren: () => import('./villages/villages.module').then(m => m.VillagesModule),
        canActivate: [AutenticatedGuard, ExternalEditorGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./config-params/config-params.module').then(m => m.ConfigParamsModule),
        canActivate: [AutenticatedGuard, ExternalEditorGuard]
      },
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      },
    ]
  },
  {
    path: 'auth',
    component: CleanComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
      }
    ]
  }
];
