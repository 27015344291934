        
<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5">
  <img [src]="currentUser.photoUrl" alt="user" class="profile-pic">
</button>
<mat-menu #profile="matMenu" class="mymegamenu">
    <!-- <button mat-menu-item>
        <mat-icon>settings</mat-icon> Settings </button>
    <button mat-menu-item>
        <mat-icon>account_box</mat-icon> Profile </button>
    <button mat-menu-item>
        <mat-icon>notifications_off</mat-icon> Disable notifications </button> -->
    <button mat-menu-item (click)="authSrv.logOut()">
        <mat-icon>exit_to_app</mat-icon> Sign Out </button>
</mat-menu>

