import { Component, OnInit } from '@angular/core';
import { IUser } from 'models/user.model';
import { AuthService } from 'services/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent implements OnInit {
  public currentUser: IUser = {};

  constructor(public authSrv: AuthService) {}

  ngOnInit() {

    this.authSrv.currentUser.subscribe((user: IUser) => {
      this.currentUser = user;
    });
  }

}
