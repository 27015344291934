import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(
    private toastController: MatSnackBar
  ) { }

  showErrorMessage(message: string, header?: string, position?: 'top' | 'bottom' | 'middle') {
    this.showMessage(message, 'danger', header, position);
  }
  showSuccessMessage(message: string, header?: string, position?: 'top' | 'bottom' | 'middle') {
    this.showMessage(message, 'success', header, position);
  }
  showWarningMessage(message: string, header?: string, position?: 'top' | 'bottom' | 'middle') {
    this.showMessage(message, 'warning', header, position);
  }

  private async showMessage(message: string, color: string, header?: string, position: 'top' | 'bottom' | 'middle' = 'top') {
    this.toastController.open(message, '', {
        duration: 5 * 1000,
        panelClass: [`bg-${color}`]
      });
  }
}
