import * as Leaflet from 'leaflet';

export { CATEGORIES, PROVINCES, TALENTS } from 'trobatea-commons';

export const pinkIcon: Leaflet.IconOptions = {
  iconUrl: 'assets/icon/marker-icon.png',
  shadowUrl: 'assets/icon/marker-shadow.png',

  iconSize: [25, 41], // size of the icon
  shadowSize: [36, 34], // size of the shadow
  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  shadowAnchor: [3, 32],  // the same for the shadow
  popupAnchor: [12, 0] // point from which the popup should open relative to the iconAnchor
};

export enum ContentStatus {
  REVISION_PENDING, VALIDATED, REJECTED = 9
}
