import { Injectable } from '@angular/core';
import { IUser } from '../../models/user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { SherpaService } from '../sherpa/sherpa.service';
import { ToasterService } from '../toaster/toaster.service';
import { SocialAuthService, GoogleLoginProvider } from 'angularx-social-login';
import { ILoginCredentials } from '../../models/auth.model';
import { Router } from '@angular/router';

const CURRENT_USER_KEY = 'currentUser';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  currentUserfavourites: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  currentUser: BehaviorSubject<IUser> = new BehaviorSubject<IUser>({});
  private accessToken: string | undefined = undefined;

  constructor(
    private router: Router,
    private sherpaSrv: SherpaService,
    private socialAuthSrv: SocialAuthService,
    private toaster: ToasterService) {
    this.initialize();
  }

  basicAuth(email: string, pass: string) {
    this.sherpaSrv.login({ email, pass, provider: 'direct' }).then((user: IUser) => {
      try {
        // Aqui esta lo de los roles
        if (user.role) {
          this.setCurrentUser(user);
        } else {
          this.toaster.showErrorMessage('Su usuario no tiene permisos suficientes para utilizar la aplicación');
        }
      } catch (e) {
        console.error(e);
      }
    },
      (err) => {
        this.toaster.showWarningMessage('Usuario o contraseña no válidas');
      });
  }

  getAccessToken(): string {
    return this.accessToken || '';
  }

  getCurrentUser(): Observable<IUser> {
    return this.currentUser.asObservable();
  }

  getUser(): IUser {
    return this.currentUser.getValue();
  }

  googleAuth() {
    this.socialAuthSrv.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  async initialize() {
    const sUser = await localStorage.getItem(CURRENT_USER_KEY);
    if (sUser) {
      let user = JSON.parse(sUser);
      try {
        this.setAccessToken(user.accessToken);
        const result = await this.sherpaSrv.checkJWT();
      } catch (e) {
        user = undefined;
      }
      this.currentUser.next(user);
    }
    this.socialAuthSrv.authState.subscribe((u: any) => {
      if (u) {
        switch (u.provider) {
          case 'GOOGLE':
            this.socialNetworkAuthLogin({
              accessToken: u.authToken,
              providerUid: u.id,
              provider: 'google'
            });
            break;
          default:
            this.toaster.showErrorMessage('Método de inicio de sesión no válido');
        }
      }
    });
  }

  isLoggedIn(): boolean {
    return (this.currentUser.value && !!this.currentUser.value.id);
  }

  logOut() {
    this.setCurrentUser({});
    try {
      this.socialAuthSrv.signOut().then(() => { }).catch(() => { });
    } catch (err) { }
    this.router.navigate(['/auth']);
  }

  public setAccessToken(token?: string) {
    this.accessToken = token;
  }

  private setCurrentUser(user: IUser) {
    if (user) {
      localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(user));
      this.setAccessToken(user.accessToken);
    } else {
      localStorage.removeItem(CURRENT_USER_KEY);
      this.setAccessToken();
    }
    this.currentUser.next(user);
  }

  async socialNetworkAuthLogin(creds: ILoginCredentials) {
    try {
      const user: IUser = await this.sherpaSrv.login(creds);
      if (user && user.role) {
        this.setCurrentUser(user);
      } else {
        this.toaster.showErrorMessage('Su usuario no tiene permisos suficientes para utilizar la aplicación');
      }
    } catch (e) {
      console.error(e);
    }
  }

  async updateUserProfile(uuid: string, data: IUser) {
    const result = await this.sherpaSrv.updateUserProfile(uuid, data);
    if (result) {
      const user = Object.assign(this.currentUser.value, data);
      this.setCurrentUser(user);
    } else {
      throw { code: 'createUserProfileException', message: 'No se ha podido registrar el perfil de usuario en sherpa' };
    }
  }

  isUserRole(role: string): boolean {
    const userRole = this.currentUser.getValue().role;
    if (!this.currentUser) {
      return false;
    }
    return userRole === role ? true : false;
  }

  getRole(): string | undefined {
    return this.currentUser.getValue().role;
  }
}
