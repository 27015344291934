import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnChanges, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatDialog, MatFormFieldControl } from '@angular/material';
import { Subject } from 'rxjs';
import { AutocompletePickerDialogComponent, ICandidate } from './autocomplete-picker-dialog/autocomplete-picker-dialog.component';

@Component({
  selector: 'app-autocomplete-picker',
  templateUrl: './autocomplete-picker.component.html',
  styleUrls: ['./autocomplete-picker.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: AutocompletePickerComponent }
  ],
})
export class AutocompletePickerComponent implements ControlValueAccessor, MatFormFieldControl<number[]>, OnDestroy {
  static nextId = 0;
  @Input() title = 'Seleccione';
  @Input() list: ICandidate[] = [];
  @Input() placeholder = '(tod@s)';
  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  focused!: boolean;
  stateChanges = new Subject<void>();
  // value
  selected: number[] = [];

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private cdr: ChangeDetectorRef,
    private elRef: ElementRef<HTMLElement>,
    public dialog: MatDialog,
    private fm: FocusMonitor,
  ) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
    fm.monitor(this.elRef.nativeElement, true).subscribe((origin: any) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  get empty(): boolean {
    return !this.selected || this.selected.length === 0;
  }

  @HostBinding() id = `html-editor-input-${AutocompletePickerComponent.nextId++}`;

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required = false;

  errorState = false;

  controlType?: string | undefined;
  autofilled?: boolean | undefined;

  setDescribedByIds(ids: string[]): void {
        // tslint:disable-next-line: no-non-null-assertion
    const controlElement = this.elRef.nativeElement
      .querySelector('.autocomplete-picker-container')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent): void {
    throw new Error('Method not implemented.');
  }

  get value() {
    return this.selected;
  }

  set value(val: number[] | null) {
    this.selected = val || [];
    this.stateChanges.next();
  }

  private onChange = (_: number[]) => { };
  private onTouch = () => { };

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  showSelectorDialog() {
    const modal = this.dialog.open(AutocompletePickerDialogComponent, {
      data: {
        list: this.list,
        selected: this.selected,
        title: this.title
      },
      position: undefined,
      minHeight: '500px',
      maxWidth: '500px',
    });
    modal.afterClosed().subscribe((result: any) => {
      if (result) {
        this.value = result;
        this.onChange(result);
        this.onTouch();
      }
    });
  }

  writeValue(val: number[]): void {
    this.selected = val ? val.slice() : val;
    this.cdr.detectChanges();
  }
}
